<template>
  <v-dialog
    v-model="show"
    max-width="700px"
    persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
    scrollable
  >
    <div class="text-center" v-if="loading" style="min-height: 500px">
      <v-progress-circular
        :size="100"
        :width="10"
        style="
          text-align: center;
          padding-top: 20px;
          padding-bottom: 20px;
          margin-top: 200px;
        "
        color="#7253CF"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-card v-else>
      <v-card-title :class="$vuetify.breakpoint.xsOnly ? ' px-0' : ''">
        <div style="display: flex; width: 100%">
          <v-spacer></v-spacer>
          <v-icon color="#2D2D2D" @click="show = false" size="32"
            >mdi-close</v-icon
          >
        </div>
      </v-card-title>
      <v-card-text class="text-center">
        <div class="primary-title">
          <v-icon class="pb-1" color="#CC0000" size="35"
            >mdi-alert-outline</v-icon
          >
          Wait a bit!
        </div>
        <div class="secondary-title">
          We already have a student with this name in the campaign.
        </div>
        <div class="py-4">
          Before you create a new student page, Please check if the below
          account belong to you and if you've forgotten the password, Use the
          reset password option below to setup a new password.
        </div>
        <v-card class="mx-auto" max-width="400" style="border-radius: 10px">
          <v-card-text>
            <div class="d-flex px-6">
              <div>
                <img
                  v-if="studentProfilePic"
                  :src="studentProfilePic"
                  width="70"
                  alt=""
                />
                <v-avatar v-else color="rgba(255, 81, 81, 0.12)" size="70">
                  <span class="orgLogo-initials-text">{{
                    getInitials(
                      studentDetail.student_first_name +
                        " " +
                        studentDetail.student_last_name
                    )
                  }}</span>
                </v-avatar>
              </div>
              <div class="ml-4">
                <div class="studentHeading">
                  <div class="d-flex">
                    <div class="studentName ma-auto">
                      {{ studentDetail.student_first_name }}
                      {{ studentDetail.student_last_name }}
                    </div>
                    <div class="ml-2">
                      <v-chip class="student py-0"> Student </v-chip>
                    </div>
                  </div>
                  <div class="text-left">
                    <span>Email - </span>{{ studentDetail.parent_email }}
                  </div>
                </div>
                <div class="text-left">
                  <div>
                    {{ studentDetail.grade_teacher }}(Grade
                    {{ studentDetail.grade_title }})
                  </div>
                  <div>
                    {{ studentDetail.school_name }}
                  </div>
                </div>
              </div>
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-center py-0">
            <div class="text-center">
              <v-btn
                text
                class="text-capitalize"
                @click="viewPublicStudentDonationPageHandler(studentDetail)"
              >
                <span style="color: #2c1963">View Student Page</span>
              </v-btn>
            </div>
            <v-divider vertical></v-divider>
            <div class="text-center d-flext justify-center">
              <v-btn
                text
                class="text-capitalize"
                @click="onClickResetLinkHandler(studentDetail)"
              >
                <span style="color: #cc0000"> Reset Password </span>
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
        <br /><br />
        <v-btn
          text
          class="text-capitalize"
          style="border: 1px solid #2c1963; border-radius: 8px"
          @click="toggleModal({ show: false })"
        >
          <span style="color: #2c1963">
            Not you? Continue to create new student page
          </span>
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
// import Axios from "@/api/BaseAxios";
import {} from "@/constants/APIUrls";
import { mapGetters, mapActions } from "vuex";
import { API_COMMON_URL } from "@/constants/urls";
import Axios from "@/api/BaseAxios";
// import Helper from "@/helper";
export default {
  name: "DuplicateStudentAlertModal",
  components: {},
  data() {
    return {
      loading: false,
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      showGetter: "publicDonationPage/getDuplicateStudentAlert",
      getCampaign: "publicDonationPage/getCampaign",
      getData: "publicDonationPage/getDuplicateStudentAlertData",
    }),
    studentDetail() {
      return this.getData;
    },
    studentProfilePic() {
      return this.getData.profile_pic;
    },
    show: {
      get() {
        return this.showGetter;
      },
      set(value) {
        this.toggleModal({ show: value });
      },
    },
  },
  watch: {
    show(value) {
      if (value) {
        setTimeout(() => {
          this.openModal();
        }, 100);
      } else {
        this.closeModal();
      }
    },
  },
  methods: {
    ...mapActions({
      toggleModal: "publicDonationPage/toggleDuplicateStudentAlertModal",
      showToast: "snackBar/showToast",
    }),
    openModal() {
      // console.log(this.studentDetail.parent_email, "fjsdjh");
    },
    closeModal() {
      this.loading = false;
    },
    getInitials(name) {
      let initials = name.split(" ");
      if (initials.length > 1) {
        initials = initials.shift().charAt(0) + initials.pop().charAt(0);
      } else {
        initials = name.substring(0, 2);
      }
      return initials.toUpperCase();
    },
    viewPublicStudentDonationPageHandler(item) {
      const { id } = item;

      const protocol = window.location.protocol;
      const subDomain = item.sub_domain + ".";
      const domain = window.location.host.substring(
        window.location.host.indexOf(".") + 1
      );
      let queryParam = "?" + "student=" + id;
      const href = protocol + "//" + subDomain + domain + queryParam;
      console.log(href);
      window.open(href);
    },
    onClickResetLinkHandler(item) {
      const { id } = item;
      const queryParam = "student=" + id;
      const host = window.location.host;
      const protocol = window.location.protocol;
      const href = protocol + "//" + host + "/parent" + "?" + queryParam;
      console.log(href);
      console.log(this.studentDetail.parent_email);

      const _this = this;
      _this.loading = true;
      /**
       * Success handler of API call
       */
      const successHandler = (res) => {
        let data = res.data;
        console.log("success ", data);
        _this.showToast({ message: data.message, color: "s" });
        _this.loading = false;
        _this.toggleModal({ show: false });
      };
      /**
       * Failure handler of API call
       */
      const failureHandler = (res) => {
        console.log("Failure ", res);
        _this.loading = false;
      };
      let formData = {};
      formData["user_email"] = this.studentDetail.parent_email;
      formData["redirect_url"] = href;

      /**
       * API Call for login
       */
      Axios.request_GET(
        API_COMMON_URL.forgotPassword,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        false
      );
    },
  },
  mounted() {},
};
</script>
<style scoped>
* {
  font-family: Lato;
}
.primary-title {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 30px;
  color: #cc0000;
}
.secondary-title {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  color: #2c1963;
}
.orgLogo-initials-text {
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  color: #ff5151;
  letter-spacing: 0px;
}
.studentName {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 100.5%;
  color: black;
}
.v-chip.student {
  background: rgba(0, 133, 255, 0.12);
  color: #0085ff;
  font-size: 17px;
}
</style>
